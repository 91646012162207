<ion-header>
  <ion-toolbar>
    <ion-title>Télécharger l'APK</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="apk-container">
    <!-- Illustration similaire à votre page actuelle -->
    <div class="apk-image">
      <ion-icon class="logo-CultG" src="assets/icon/cultG.svg"></ion-icon>
    </div>

    <!-- Texte d'information -->
    <p class="apk-description">
      Téléchargez l'APK et profitez de votre application où que vous soyez !
    </p>

    <!-- Bouton de téléchargement avec logo Android -->
    <ion-button expand="block" fill="solid" class="download-button" (click)="downloadApk()">
      <ion-icon name="logo-android" slot="start"></ion-icon>
      Télécharger pour Android
    </ion-button>

    <ion-button expand="block" fill="solid" class="download-button" [disabled]="true">
      <ion-icon name="logo-apple" slot="start"></ion-icon>
      Télécharger pour Apple IOS
    </ion-button>
  </div>
</ion-content>
