import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {addIcons} from "ionicons";
import * as ionIcons from "ionicons/icons";

@Component({
  selector: 'cultg-download',
  templateUrl: './download.page.html',
  styleUrls: ['./download.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class DownloadPage{

  constructor() {
    addIcons(ionIcons);
  }

  downloadApk() {
    const apkPath = 'assets/cultG.apk';  // Chemin relatif vers votre APK dans le dossier assets
    const link = document.createElement('a');
    link.href = apkPath;
    link.download = 'cultG.apk';  // Nom du fichier lors du téléchargement
    link.click();
  }

}
